import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const ThankYou = () => (
  <Layout>
    <h1>Thank you for getting in contact</h1>
    <p>We'll be in touch soon!</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ThankYou
